export const useCountdown = max => {
  let count;
  const min = 0;
  let token = null;
  let callback;
  const runTimer = () => {
    callback?.(count);
    if (count > min) {
      token = setTimeout(() => {
        count--;
        runTimer();
      }, 1000);
    }
  };
  const startCount = cb => {
    callback = cb;
    clearTimeout(token);
    token = null;
    count = max;
    runTimer();
  };
  const stopCount = () => {
    callback?.(min);
    clearTimeout(token);
    token = null;
  };
  return { startCount, stopCount };
};

export const checkOverWeight = totalWeight => {
  if (!totalWeight) return false;
  const number = +totalWeight;
  return number > 4500;
};

export const getOrgnazitionByPlate = ({ province, city, licensePlate }) => {
  if (!licensePlate) return null;
  const firstLetter = licensePlate.substr(0, 1);
  const first2Letter = licensePlate.substr(0, 2);
  const zhixiaCityMap = {
    津: '天津市公安交通管理局',
    京: '北京市公安局公安交通管理局',
    渝: '重庆市公安局交通管理局',
    沪: '上海市公安局交通警察总队'
  };
  const val = zhixiaCityMap[firstLetter];
  if (val) return val;
  if (first2Letter === '辽C') {
    return `${province}${city}公安局公安交通管理局`;
  }
  const specialA = ['冀A', '苏A', '贵A'];
  if (specialA.indexOf(first2Letter) > -1) {
    return `${province}${city}公安局交通管理局`;
  }
  const specialMeng = ['蒙B', '蒙K', '蒙G', '蒙H', '蒙J'];
  if (specialMeng.indexOf(first2Letter) > -1) {
    return `${province}${city}公安局交通管理支队`;
  }
  return `${province}${city}公安局交通警察支队`;
};
