import { formatToDateTime } from '@wlhy-web-lib/shared';
import dayjs from 'dayjs';

export const validateTimeRanges = (startTime, endTime, rules) => {
  let timeHf = false;
  let startObj = {
    year: new Date(startTime).getFullYear(),
    month: new Date(startTime).getMonth(),
    day: new Date(startTime).getDate()
  };
  let endObj = {
    year: new Date(endTime).getFullYear(),
    month: new Date(endTime).getMonth(),
    day: new Date(endTime).getDate()
  };
  let allowYears = rules.map(item =>
    formatToDateTime(
      new Date(`${startObj.year}-${startObj.month + 1}-${startObj.day}`).setFullYear(startObj.year + item),
      'yyyy-MM-dd'
    )
  );
  if (endObj.year == '2199') {
    // 长期任何时间都能通过
    timeHf = true;
  } else {
    if (rules.includes(endObj.year - startObj.year)) {
      // 固定年份
      if (startObj.month === 1 && startObj.day === 29) {
        timeHf = true;
      } else {
        timeHf = allowYears.includes(formatToDateTime(endTime, 'yyyy-MM-dd'));
      }
    } else {
      timeHf = false;
    }
  }
  return timeHf;
};

export const isExpiredDay = value => {
  const momentDate = dayjs(value)
    .startOf('day')
    .valueOf();
  const nowDate = dayjs()
    .startOf('day')
    .valueOf();
  return momentDate < nowDate;
};

export const isExpiredMonth = value => {
  const momentDate = dayjs(value)
    .startOf('month')
    .valueOf();
  const nowDate = dayjs()
    .startOf('month')
    .valueOf();
  return momentDate < nowDate;
};

export const parseOcrDate = dateStr => {
  if (!dateStr) return null;
  if (dateStr === '长期') {
    return dayjs('2199/12/31').valueOf();
  }
  const date = dayjs(dateStr);
  if (date.isValid()) return date.valueOf();
  return null;
};
